/* @import url('pretendard/dist/web/static/pretendard.css'); */
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.css');
@tailwind base;
@tailwind utilities;

body {
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic',
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  margin: 0;
  padding: 0;
  touch-action: pan-y;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  overflow-y: hidden;
  letter-spacing: -0.25px;
  color: #1f2023 !important;

  /* 제거 예정 */
  --brand1: #0039a7;
  --sub1: #002e87;
  --sub2: #2d6adf;
  --sub3: #e2ecff;
  --sub4: #10b8c2;
  --sub5: #e9f9fa;
  --positive1: #00e19e;
  --positive2: #e5fcf5;
  --negative1: #d8204c;
  --negative2: #fcdfe6;
  --alert1: #ffcc4d;
  --alert2: #fff6df;
  --black: #000000;
  --white: #ffffff;
  --gray-900: #1f2023;
  --gray-800: #34363b;
  --gray-700: #565860;
  --gray-600: #656871;
  --gray-500: #858994;
  --gray-400: #abb0bf;
  --gray-300: #c8cddb;
  --gray-200: #e7eaf4;
  --gray-100: #f2f5fc;
  --font-size-1: 24px;
  --font-size-2: 20px;
  --font-size-3: 16px;
  --font-size-4: 14px;
  --font-size-5: 12px;
  --font-size-6: 10px;
  --line-height-1: 36px;
  --line-height-2: 30px;
  --line-height-3: 24px;
  --line-height-4: 22px;
  --line-height-5: 18px;
  --line-height-6: 14px;
  --shadow-100: rgba(26, 30, 39, 0.16);
}
img {
  width: 100%;
}
input {
  border: 0px;
}
/* placeholder */
&:empty:before {
  content: attr(placeholder);
  color: grey;
  display: inline-block;
}
input:focus,
div:focus {
  outline: none;
}
#root {
  width: 100%;
  height: 100vh;
  display: flex;
}

/* 아래 클래스 모두 제거 예정 */
.heading-1 {
  font-weight: bold;
  font-size: var(--font-size-1);
}

.heading-2 {
  font-weight: bold;
  font-size: var(--font-size-2);
}

.heading-3 {
  font-weight: bold;
}

.heading-4 {
  font-weight: bold;
  font-size: var(--font-size-4);
}

.heading-5 {
  font-weight: bold;
  font-size: var(--font-size-5);
}

.heading-6 {
  font-weight: bold;
  font-size: var(--font-size-6);
}

.blockquote {
  font-weight: normal;
  font-size: 12px;
}

.text-gray900 {
  color: var(--gray-900);
}
.text-gray800 {
  color: var(--gray-800);
}
.text-gray700 {
  color: var(--gray-700);
}
.text-gray600 {
  color: var(--gray-600);
}
.text-gray500 {
  color: var(--gray-500);
}
.text-gray400 {
  color: var(--gray-400);
}
.text-gray300 {
  color: var(--gray-300);
}
.text-gray200 {
  color: var(--gray-200);
}
.text-gray100 {
  color: var(--gray-100);
}
.text-black {
  color: var(--black);
}
.text-white {
  color: var(--white);
}
.bg-brand1 {
  background-color: var(--brand1);
}
.bg-gray900 {
  background-color: var(--gray-900);
}
.bg-gray800 {
  background-color: var(--gray-800);
}
.bg-gray700 {
  background-color: var(--gray-700);
}
.bg-gray600 {
  background-color: var(--gray-600);
}
.bg-gray500 {
  background-color: var(--gray-500);
}
.bg-gray400 {
  background-color: var(--gray-400);
}
.bg-gray300 {
  background-color: var(--gray-300);
}
.bg-gray200 {
  background-color: var(--gray-200);
}
.bg-gray100 {
  background-color: var(--gray-100);
}
.bg-black {
  background-color: var(--black);
}
.bg-white {
  background-color: var(--white);
}
